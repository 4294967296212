.nosotros {
    background-color: #F2F2F2;
    .main {
        // overflow: hidden;
        min-height: 100vh;
        min-width: 100%;
        background-color: #F2F2F2;
        &::before {
            content: "";
            height: 100vh;
            z-index: 0;
            position: absolute;
            left: -20vw;
            right: 0;
            bottom: 0;
            top: 0;
            width: 56% !important;
            background: transparent linear-gradient(90deg, $primary-color 0%, $primary-color 100%) 0% 0% no-repeat padding-box;
            box-shadow: 0px 5px 15px #00000033 !important;
            transform: skew(8deg) !important;
        }
        // &::after {
        //   font-family: "icomoon" !important;
        //   position: absolute;
        //   content: "\e907";
        //   width: 30rem;
        //   height: 30rem;
        //   z-index: 6;
        //   // font-size: 2rem;
        //   // left: -2rem;
        //   // transition: all 0.5s;
        // }
        .corte_diagonal {
            padding-bottom: 8.5vh;
        }
        .row {
            .brad_crumbs_nosotros {
                div {
                    nav {
                        ol {
                            //   justify-content: center;
                            a {
                                color: white !important;
                            }
                            li {
                                color: #fff !important;
                                font-family: 'Lato-Bold', sans-serif;
                                // font-size: .8rem;
                                &::before {
                                    color: white;
                                }
                            }
                        }
                    }
                }
            }
            height: 100vh;
            .corte_diagonal {
                .text_acerca {
                    font-size: 1.5rem;
                    color: #fff;
                    margin: 5vh 0;
                }
                .title_nosotros {
                    font-size: 5.5rem;
                    color: #fff;
                    font-family: 'Raleway-ExtraBold', sans-serif;
                    margin-top: 3vh;
                    margin-bottom: 15vh;
                }
                .content-logo {
                    position: relative;
                    .logo-absolute {
                        // width: 20vh;
                        // top: -10rem;
                        left: 2vw;
                        bottom: -15vh;
                        position: absolute;
                        // height: 22vh;
                        z-index: 3;
                        width: 14rem;
                    }
                }
                .rotating {
                    -webkit-animation: rotating 20s linear infinite;
                    -moz-animation: rotating 20s linear infinite;
                    -ms-animation: rotating 20s linear infinite;
                    -o-animation: rotating 20s linear infinite;
                    animation: rotating 20s linear infinite;
                }
            }
            .h-100 {
                height: 100vh;
                // h5 {
                //   font-size: 1rem;
                //   font-weight: 400;
                //   color: #ffff;
                //   line-height: 1.6;
                //   // letter-spacing: px;
                //   br {
                //     margin-bottom: 0.5rem;
                //     display: block;
                //   }
                //   span {
                //     width: 10rem;
                //     background-color: #fff;
                //     height: 1px;
                //     vertical-align: middle;
                //   }
                // }
                // img {
                //   width: 100%;
                //   height: 45vh;
                //   object-fit: cover;
                //   object-position: center;
                // }
                .content-main {
                    padding: 5.25rem 5rem;
                    .div_body_nosotros {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        .title {
                            h3 {
                                color: $primary-color;
                                font-size: 1.5rem;
                            }
                        }
                        .text {
                            p {
                                font-size: 1.6rem;
                                color: $bg-primary;
                                line-height: 1.3;
                                font-family: 'Lato-Medium', sans-serif;
                                margin-top: 2rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1700px) {
    .nosotros {
        .main {
            &::before {
                left: -24rem;
                width: 57% !important;
                // background: transparent linear-gradient(90deg, #646464 0%, #323232 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 5px 15px #00000033;
                transform: skew(8deg) !important;
            }
            .row {
                .text {
                    p {
                        font-size: 1.05rem !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 1500px) {
    .nosotros {
        .main {
            &::before {
                left: -24rem;
                width: 57% !important;
                // background: transparent linear-gradient(90deg, #646464 0%, #323232 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 5px 15px #00000033;
                transform: skew(8deg) !important;
            }
        }
    }
}

@media (max-width: 1300px) {
    .nosotros {
        .main {
            &::before {
                left: -24rem;
                width: 66% !important;
                // background: transparent linear-gradient(90deg, #646464 0%, #323232 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 5px 15px #00000033;
                transform: skew(5deg) !important;
            }
        }
    }
}

@media (min-width: 992px) {
    .nosotros {
        .main {
            .full-fluid {
                max-width: 95%;
                margin-left: auto;
                padding-right: 0;
                margin-right: 0;
                position: relative;
            }
        }
    }
}

@media (max-width: 992px) {
    .nosotros {
        .main {
            background: linear-gradient(90deg, white 0%, #ebebeb 100%);
            min-height: unset;
            &::before {
                opacity: 0.9;
                left: 0rem;
                width: 100% !important;
                background: transparent linear-gradient(90deg, #646464 0%, #323232 100%) 0% 0% no-repeat padding-box;
                box-shadow: 0px 5px 15px #00000033;
                transform: skew(360deg) !important;
            }
            .row {
                padding-top: 11rem;
                .corte_diagonal {
                    .content-logo {
                        display: none !important;
                    }
                    .text_acerca {
                        margin-top: 0;
                        font-size: 1.3rem;
                    }
                    .title_nosotros {
                        margin-bottom: 0;
                        font-size: 4rem;
                    }
                }
                text-align: center;
                height: unset;
                position: relative;
                .h-100 {
                    // height: 100vh !important;
                    h2 {
                        font-size: 1.5rem;
                        margin-bottom: 10vh;
                        margin-top: 0;
                    }
                    h5 {
                        font-size: 1rem;
                        font-weight: 400;
                        color: #ffff;
                        line-height: 1.6;
                        // letter-spacing: px;
                        br {
                            margin-bottom: 0.5rem;
                            display: block;
                        }
                        span {
                            width: 10rem;
                            background-color: #fff;
                            height: 1px;
                            vertical-align: middle;
                        }
                    }
                    img {
                        width: 100%;
                        height: 100vh;
                        position: absolute;
                        left: 0;
                        top: 0;
                        object-fit: cover;
                        object-position: center;
                        bottom: 0;
                        right: 0;
                        z-index: -1;
                    }
                    .content-main {
                        padding: 10vh 0rem;
                        background: linear-gradient( 90deg, rgba(255, 255, 255, 1) 0%, rgba(235, 235, 235, 1) 100%);
                        &.h-100 {
                            height: unset;
                        }
                        .div_body_nosotros {
                            margin-top: 0rem;
                        }
                        span {
                            margin-bottom: 1rem;
                            display: block;
                            font-size: 1.3rem;
                        }
                        p {
                            font-size: 1rem;
                            line-height: 1.3;
                            width: 90%;
                            margin-left: auto;
                            margin-right: auto;
                            br {
                                display: none;
                            }
                        }
                    }
                }
                .h-sm-auto {
                    height: unset !important;
                }
            }
        }
    }
}