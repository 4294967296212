.contact-main {
    width: 100%;
    input,
    textarea {
        width: 100%;
        background-color: transparent;
        border: none;
        font-size: 1rem;
        font-weight: 400;
        margin-bottom: 0.3rem;
        // border-radius: 5px;
        padding: 1.5rem 2.3rem;
        border: 1px solid $bg-secondary;
        &:focus {
            outline: none;
        }
        &::placeholder {
            color: #1d1d1d;
        }
    }
    #text-form {
        color: #4bb543;
        font-weight: 300;
    }
}

#formContact {
    div {
        div {
            p {
                right: 114px;
                color: $secondary-color;
                font-size: 1.2rem;
            }
            .btn {
                border: none;
                transform: none;
                &:hover {
                    border: none;
                    transform: none;
                }
            }
            .btn-effect {
                width: auto;
                border-radius: 0;
                padding: 0 !important;
                padding-right: 0 !important;
                padding-left: 0 !important;
                padding-bottom: 0 !important;
                padding-top: 0 !important;
                color: $primary-color;
                position: relative;
                overflow: visible;
                top: 0;
                &:hover {
                    border: none;
                    transform: none;
                    transition: 0.5s ease all;
                    .btn_propiedad_enviar {
                        color: $bg-quarter;
                        &::before {
                            background: $primary-color;
                            z-index: -1;
                        }
                    }
                    &::before {
                        left: 8rem;
                        z-index: 3;
                        background: $primary-color !important;
                    }
                    &::after {
                        left: 8rem !important;
                        z-index: 4 !important;
                        right: none;
                    }
                }
                .btn_propiedad_enviar {
                    color: $bg-primary;
                    padding-right: 2rem !important;
                    padding-left: 2rem !important;
                    padding-bottom: 1rem !important;
                    padding-top: 1rem !important;
                    position: relative;
                    right: 0;
                    margin: 0;
                    overflow: visible;
                    &::before {
                        content: " ";
                        position: absolute;
                        width: 9rem;
                        height: 3.45rem;
                        transform: skew(340deg);
                        top: 0;
                        left: 0;
                        border: 1px solid $primary-color;
                    }
                }
                &::before {
                    content: "";
                    position: absolute;
                    left: -4rem;
                    top: 0;
                    z-index: 1;
                    background: $primary-color;
                    color: #fff;
                    transform: skew(340deg);
                    transition: 0.5s ease all;
                }
                &::after {
                    content: "\e904";
                    font-family: "icomoon" !important;
                    line-height: 1;
                    display: flex;
                    font-size: 1.5rem;
                    padding-bottom: 3px;
                    font-weight: 400;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    left: -4rem;
                    width: 4rem;
                    top: .25rem;
                    bottom: -1px;
                    text-transform: uppercase;
                    background: none;
                    color: #fff;
                    z-index: 1;
                    transition: 0.5s ease all;
                }
            }
        }
    }
}

@media (max-width: 1920px) {
    .btn-effect {
        &:hover {
            .btn_propiedad_enviar {
                &::before {}
            }
            &::before {}
            &::after {}
        }
        .btn_propiedad_enviar {
            &::before {
                height: 3.5rem;
            }
        }
        &::before {
            height: 3.5rem;
        }
        &::after {}
    }
}

@media (max-width: 1400px) {
    .btn-effect {
        &:hover {
            .btn_propiedad_enviar {
                &::before {}
            }
            &::before {}
            &::after {}
        }
        .btn_propiedad_enviar {
            &::before {
                height: 3.4rem !important;
            }
        }
        &::before {
            // top: 4px !important;
            height: 3.4rem !important;
        }
        &::after {}
    }
}

@media (max-width: 1200px) {
    .div_form_captcha {
        display: block !important;
        .btn-effect {
            left: 8rem;
            .btn_propiedad_enviar {
                top: 4px;
                &::before {
                    height: 3.4rem !important;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .contact-main {
        input,
        textarea {
            font-size: 1rem;
            margin-bottom: 1.5rem !important;
            display: block;
            padding: 0.6rem;
            font-weight: 300;
            &::placeholder {
                color: #6a6a6a;
            }
        }
        textarea {
            height: min-content !important;
        }
    }
    .div_form_captcha {
        margin-top: 3rem;
        .btn-effect {
            .btn_propiedad_enviar {
                top: 4px;
                &::before {
                    height: 3.4rem !important;
                }
            }
            &::before {
                top: 4px !important;
                height: 3.4rem !important;
            }
        }
    }
}