.Emprendimiento {
    .lotes {
        padding-bottom: 10vh;
        h2 {
            font-size: 4rem;
            font-weight: 300;
            font-family: 'Raleway-ExtraBold', sans-serif;
            color: $bg-primary;
        }
        .row {
            max-width: 90%;
            margin: auto;
            h5 {
                font-weight: 600;
                font-size: 1rem;
            }
            p {
                font-weight: 400;
                font-size: 1.2rem;
                margin-bottom: 0;
                display: block;
            }
            .btn {
                width: 2.5rem;
                height: 2.5rem;
                background: none !important;
                // border-radius: 100%;
                // border: 2px solid $primary-color;
                color: $primary-color;
                font-size: 1.5rem;
                &:hover {
                    background: #fff !important;
                    color: $primary-color !important;
                    border: 2px solid $primary-color;
                }
            }
        }
        .underlined {
            // border-bottom: 1px solid #070707;
            background: #052e3e26 0% 0% no-repeat padding-box;
        }
    }
}

@media (max-width: 992px) {
    .Emprendimiento {
        .lotes {
            padding: 0vh 0;
            h2 {
                font-size: 2.2rem;
                margin-bottom: 2.2rem;
            }
            .row {
                max-width: 100%;
                h5 {
                    font-size: 0.9rem;
                }
                p {
                    font-size: 1rem;
                }
                .btn {
                    width: 2rem;
                    height: 2rem;
                    font-size: 0.9rem;
                }
            }
        }
    }
}