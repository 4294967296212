.Inicio {
    .about-section {
        background-color: #efefef;
        padding-bottom: 12vh;
        position: relative;
        .content-logo {
            .logo-absolute {
                width: 20vh;
                top: -10vw;
                right: 10vw;
                position: absolute;
                height: 20vh;
                z-index: 9999;
            }
        }
        .btn {
            background: transparent;
            position: relative;
            margin-top: 4rem;
            display: inline-block !important;
            overflow: visible;
            font-family: 'Lato-Regular', sans-serif;
            left: -1.5rem;
            line-height: 1;
            padding-left: 3.5rem;
            border: none;
            bottom: 15%;
            margin-left: 75px;
            font-size: 1;
            width: max-content !important;
            &:hover {
                border: none !important;
                color: $primary-color;
                transform: none !important;
                transform: skew(40deg);
                color: #ffffff !important;
                &::after {
                    content: "";
                    height: 3.2rem;
                    width: 80%;
                    top: 0;
                    background: #ed362d;
                    // z-index: 2 !important;
                    border: 1px solid #ed362d;
                    left: 22px;
                    position: absolute;
                    transform: skew(340deg);
                }
                &::before {
                    transition: 0.5s ease all;
                    left: 87%;
                }
                .btn-effect {
                    color: white !important;
                    z-index: 9;
                }
                span {
                    left: 86% !important;
                }
            }
            &::before {
                font-family: "icomoon" !important;
                speak: never;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\e904";
                font-size: 1rem;
                background: none;
                left: -2rem;
                z-index: 3 !important;
            }
            &::after {
                content: "";
                height: 3.2rem;
                width: 80%;
                top: 0;
                background: transparent;
                // z-index: 3 !important;
                border: 1px solid #ed362d;
                left: 1.2rem;
                position: absolute;
                transform: skew(340deg);
            }
            span {
                content: "";
                height: 3.2rem;
                width: 3.5rem;
                top: -1rem;
                font-size: 1rem;
                background: #ed362d;
                z-index: 2 !important;
                left: -2rem;
                position: absolute;
                transform: skew(340deg);
                transition: 0.5s ease all;
            }
        }
        .rotating {
            -webkit-animation: rotating 20s linear infinite;
            -moz-animation: rotating 20s linear infinite;
            -ms-animation: rotating 20s linear infinite;
            -o-animation: rotating 20s linear infinite;
            animation: rotating 20s linear infinite;
        }
        h2 {
            color: $bg-primary;
            font-family: 'Raleway-Bold', sans-serif;
            font-size: 4rem;
            margin-bottom: 7vh;
        }
        span {
            font-size: 1.3rem;
            font-family: 'Lato-Regular', sans-serif;
            margin-top: 1rem;
            color: $primary-color;
            .line {
                width: 10vw;
                background-color: $primary-color;
                height: 1px;
                position: relative;
                display: block;
            }
        }
        p {
            color: $secondary-color;
            font-size: 1.3rem;
            display: block;
            font-family: 'Lato-Regular', sans-serif;
            margin-bottom: 4rem;
        }
        .top-image {
            position: relative;
            margin-bottom: 5rem;
            display: block;
            img {
                object-fit: cover;
                object-position: top;
                height: 45vh;
                width: 100%;
            }
            &::before {
                content: '';
                left: 0;
                top: 0;
                z-index: 6;
                right: 0;
                bottom: 0;
                background: $primary-color;
                opacity: .5;
                position: absolute;
            }
        }
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media (max-width:992px) {
    .Inicio {
        .about-section {
            h2 {
                font-size: 2.5rem;
                margin-bottom: 5vh;
            }
            p {
                font-size: 1.2rem;
                line-height: 1.3;
                margin-top: 2rem;
            }
            .btn {
                margin-left: 1rem;
                transform: scale(.7);
                &:hover {
                    transform: scale(.7) !important;
                }
            }
        }
    }
}