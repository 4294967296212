.Resultados {
    .loading-section {
        height: 50vh !important;
    }
    .main {
        padding-top: 4.5rem;
        padding-bottom: 7rem;
        #resizing_select {
            width: 9rem;
            height: 1.2rem;
            text-transform: uppercase;
        }
        #width_tmp_select {
            display: none;
        }
        h5 {
            font-size: 1.5rem;
            color: $secondary-color;
            font-weight: 430;
        }
        h2 {
            font-size: 5rem;
            font-family: 'Raleway-Regular';
            color: $bg-primary;
            span {
                color: $primary-color;
                font-size: 5rem;
                font-family: 'Raleway-Regular';
            }
        }
        span {
            font-size: 1rem;
            color: #000;
            width: 100%;
            margin-bottom: 1rem;
            line-height: 1.2;
            font-family: 'Raleway-Medium';
            text-transform: uppercase;
            button {
                border: none;
                color: #000;
                font-family: 'Raleway-Medium';
                background-color: transparent;
                i {
                    color: $primary-color;
                }
            }
            select {
                border: none;
                line-height: 1.2;
                display: inline-block;
                font-size: 1rem;
                // height: 1rem;
                background-color: transparent;
                color: #000;
                font-family: 'Raleway-Medium';
            }
        }
    }
}

@media (max-width: 1400px) {
    .Resultados {
        // .loading-section {
        //   height: 50vh !important;
        // }
        .main {
            // padding-top: 4.5rem;
            // padding-bottom: 7rem;
            // #resizing_select {
            //   width: 9rem;
            // }
            // #width_tmp_select {
            //   display: none;
            // }
            h5 {
                font-size: 1.3rem;
                // color: #1d1d1d;
                // font-weight: 430;
            }
            h2 {
                font-weight: 430;
                font-size: 3.5rem;
                color: $bg-secondary;
                span {
                    color: $bg-primary;
                    font-size: 3.5rem;
                    font-weight: 430;
                }
            }
            span {
                font-size: 0.9rem;
                select {
                    font-size: 0.8rem;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .Resultados {
        .main {
            padding-bottom: 2rem;
            padding-top: 12rem;
            button {
                background-color: $bg-terciary;
                color: #fff;
                border: none;
                padding: 1rem;
                position: absolute;
                top: 6rem;
                left: 0;
                right: 0;
                z-index: 9;
                width: 100%;
                transform: 0.4s ease all;
                display: flex !important;
                align-items: center;
                justify-content: center;
                img {
                    width: 1.2rem;
                }
                &.filters-top {
                    position: fixed;
                    top: 0;
                }
            }
            h5 {
                font-size: 0.8rem;
            }
            h2 {
                font-size: 1.8rem;
                width: 80%;
                span {
                    font-size: 1.8rem;
                }
            }
            span {
                font-size: 0.7rem;
            }
        }
    }
}