a {
    text-decoration: none !important;
}

.property-card {
    position: relative;
    box-shadow: 10px 10px 30px #00000020;
    color: $default-font-color;
    text-decoration: none !important;
    &:hover {
        cursor: pointer;
        .content-header {
            background-color: $bg-primary;
            &::after {
                opacity: 1;
                height: 100rem;
                width: 165rem;
                transform: translate(-15rem, -15rem), rotate(45deg);
                // bottom: -400px;
            }
            .type-property {
                width: 71%;
                &::after {
                    display: block;
                }
                a {
                    display: none;
                }
            }
            p {
                transition: 3s ease all;
                display: block;
            }
            img {
                // transform: scale(1.2);
                // opacity: 30%;
            }
        }
    }
    .top-operations {
        position: absolute;
        display: flex;
        z-index: 4;
        top: 0rem;
        right: 0rem;
        .top-card {
            height: 35px;
            width: 105px;
            padding-right: 1rem;
            position: relative;
            font-size: 0.8rem;
            background: $bg-terciary;
            &.Alquiler {
                background: $bg-quarter;
                span {
                    color: $bg-terciary;
                }
                &::before {
                    background: linear-gradient(300deg, $bg-quarter 40%, transparent 7%);
                }
            }
            &::before {
                content: "";
                background: linear-gradient(300deg, $bg-terciary 40%, transparent 7%);
                height: 35px;
                width: 37px;
                top: 0;
                z-index: 3;
                left: -35px;
                position: absolute;
            }
            span {
                font-size: 1rem;
                position: absolute;
                top: 6px;
            }
            .Venta {
                left: 12%;
            }
            .Alquiler {
                left: 6%;
            }
            .operation-property {
                color: #fff;
                text-transform: uppercase;
                font-weight: 300;
            }
            .btn-like {
                display: flex;
                padding: 0 1rem 0;
                justify-content: center;
                border: none;
                align-items: center;
                vertical-align: middle;
                background-color: transparent;
                transition: 0.5s ease all;
                z-index: 9999;
                i {
                    transition: 0.5s ease all;
                    // color: $primary-color;
                    color: transparent;
                    -webkit-text-stroke: 2px $primary-color;
                    font-size: 1.2rem;
                }
                &:hover {
                    // background: $tertiary-color;
                    i {
                        color: $primary-color;
                        // -webkit-text-stroke: 2px transparent;
                    }
                }
                &.active {
                    i {
                        color: $primary-color;
                    }
                }
            }
        }
    }
    .content-header {
        position: relative;
        overflow: hidden;
        &::after {
            content: "";
            height: 20rem;
            width: 20rem;
            background: transparent linear-gradient( 123deg, #ed372dd2 0%, #cc3830ce 47%, #951612c7 100%) 0% 0% no-repeat padding-box;
            z-index: 4;
            position: absolute;
            right: -12rem;
            transform: rotate( 45deg);
            transition: 1s all ease;
            bottom: -12rem;
            opacity: 0;
        }
        p {
            position: absolute;
            // top: 40%; // No es lo mejor
            // left: 30%; // No es lo mejor
            left: 50%; // Funciona en todas las pantallas
            top: 50%; // Funciona en todas las pantallas
            transform: translate(-50%, -50%); //Funciona en todas las pantallas
            font-size: 2rem;
            color: white;
            display: none;
            padding: 0;
        }
        a {
            img {
                width: 100%;
                transition: 0.5s ease all;
                max-height: 30vh;
                height: 30vh;
                object-fit: cover;
                object-position: center;
                border-radius: 0px !important;
            }
        }
        .type-property {
            //   position: relative;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            background: $bg-primary;
            padding: 0.5rem;
            font-weight: 430;
            line-height: 1;
            padding-left: 1rem;
            color: #ffffff;
            display: flex;
            justify-content: space-between;
            font-size: 1.1rem;
            text-transform: uppercase;
            &::after {
                display: none;
                content: "";
                background: linear-gradient(130deg, $bg-primary 40%, transparent 7%);
                height: 40px;
                width: 97px;
                top: 0;
                z-index: 3;
                left: 95%;
                position: absolute;
            }
            .button_view_more {
                padding: 0 !important;
                background: $primary-color;
                position: absolute;
                right: 0rem;
                width: 7rem;
                top: 0rem;
                bottom: 0rem;
                &::before {
                    content: "";
                    background: linear-gradient(300deg, $primary-color 40%, transparent 7%);
                    height: 40px;
                    width: 67px;
                    top: 0;
                    z-index: 3;
                    left: -54px;
                    position: absolute;
                }
                span {
                    width: 1.3rem;
                    height: 0.1rem;
                    position: absolute;
                    left: 2.5rem;
                    top: 0.2rem;
                    background-color: #ffffff;
                    display: block;
                    &:nth-child(1) {
                        transform: rotate(90deg) translate(12px, 0px);
                    }
                    &:nth-child(2) {
                        transform: rotate(360deg) translate(0px, 12px);
                    }
                }
            }
        }
    }
    .content-body {
        padding: 1.87rem 1.87rem 0 1.87rem;
        background-color: #fff;
        a {
            color: $bg-primary;
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
            .title {
                font-size: 1.8rem;
                font-family: 'Lato-Medium', sans-serif;
                margin-bottom: 1rem;
                color: $bg-primary;
            }
        }
        .location {
            color: $bg-primary;
            i {
                font-size: 1rem;
                font-weight: 400;
                color: $bg-primary;
            }
            margin-bottom: 1.2rem;
        }
        .details {
            &_item {
                color: $bg-primary;
                font-size: 1rem;
                font-family: 'Lato-Semibold', sans-serif;
                i {
                    font-size: 1rem;
                    &::before {
                        color: $bg-primary !important;
                    }
                    &.icon-surface-terreno {
                        font-size: 1.2rem;
                    }
                }
            }
        }
        &_bottom {
            margin-top: 3rem;
            padding-bottom: 1.75rem;
            align-items: center;
            .price {
                font-size: 2.25rem;
                color: $bg-primary;
            }
            .btn-more {
                width: 2.25rem;
                padding: 0;
                height: 2.25rem;
                background: $tertiary-color;
                color: #fff;
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                vertical-align: middle;
                border: 2px solid $primary-color;
                text-decoration: none;
                span {
                    position: absolute;
                    top: 50%;
                    height: 2rem;
                    font-weight: 400;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 1.5rem;
                    line-height: initial;
                    display: block;
                }
                &:hover {
                    background: transparent;
                    border: 2px solid $primary-color;
                    color: $primary-color;
                }
            }
        }
    }
    .btn-like {
        bottom: 3rem;
        background-color: #efefef;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        border: none;
        align-items: center;
        vertical-align: middle;
        transition: 0.5s ease all;
        right: 1.2rem;
        z-index: 9999;
        // position: absolute;
        &.active {
            i {
                color: $primary-color;
            }
        }
        i {
            transition: 0.5s ease all;
            // color: $primary-color;
            color: transparent;
            -webkit-text-stroke: 1px #c42127;
            font-size: 1rem;
        }
        &:hover {
            // background: $tertiary-color;
            i {
                color: $primary-color;
            }
        }
    }
    &.list {
        // height: 100%;
        background-color: #fff;
        .content-header {
            width: 70%;
            overflow: hidden;
            max-height: 100%;
            height: 30vh;
            img {
                max-height: 100%;
                height: 30vh;
                overflow: hidden;
                border-radius: 0px 0px 20px 0px;
            }
            // p {
            //     top: 34%;
            //     left: 24%;
            // }
            &:hover {
                .type-property {
                    width: 65% !important;
                }
            }
        }
        .content-body {
            width: 65%;
            padding-left: 3rem;
            a {
                max-width: 90%;
            }
            .list-items {
                .type-property {
                    color: #5b5b5b;
                    background-color: transparent;
                    border: 2px solid #5b5b5b;
                    border-radius: 5px;
                    line-height: unset;
                    font-size: 0.8rem;
                    letter-spacing: -0.2px;
                    padding: 0.1rem 1.2rem;
                    font-weight: 400;
                    position: relative;
                }
            }
            .details_item,
            .details i,
            .location {
                color: #a7a7a7;
            }
            .location {
                font-weight: 300;
            }
            &_bottom {
                margin-top: 2rem;
                padding-top: 1.3rem;
                padding-bottom: 0rem;
                border-top: 1px solid #c9c9c9;
                .btn-more {
                    span {
                        height: 2.2rem;
                    }
                }
            }
        }
    }
}

@media (min-width: 1920px) {
    .property-card {
        &:hover {
            .content-header {
                .type-property {
                    &::after {
                        left: 96%;
                    }
                }
            }
            .type-property {
                &::after {
                    left: 80%;
                }
            }
        }
    }
}

@media (max-width: 1400px) {
    .property-card.list {
        .content-header {
            width: 70%;
            overflow: hidden;
            max-height: 100%;
            height: 26vh;
        }
    }
    .property-card {
        &:hover {
            .content-header {
                .type-property {
                    width: 60%;
                    &::after {
                        right: 3rem;
                    }
                }
            }
        }
        .top-card {
            height: 30px;
            width: 90px;
            &::before {
                content: "";
                background: linear-gradient(300deg, $bg-terciary 40%, transparent 7%);
                height: 30px;
                width: 50px;
                top: 0;
                z-index: 3;
                left: -39px;
                position: absolute;
            }
            span {
                font-size: 1rem;
                top: 6px;
            }
        }
        .content-header {
            p {
                // left: 25%;
            }
            position: relative;
            overflow: hidden;
            img {
                height: 19rem;
                border-radius: none !important;
            }
            .type-property {
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                background: $bg-terciary;
                padding: 0.5rem;
                font-weight: 430;
                line-height: 1;
                padding-left: 1rem;
                color: #ffffff;
                display: flex;
                justify-content: space-between;
                font-size: 1.1rem;
                text-transform: uppercase;
                a {
                    text-decoration: none;
                    color: #ffffff;
                    position: absolute;
                    right: 0rem;
                    width: 7rem;
                    top: 0rem;
                    bottom: 0rem;
                    line-height: 1.5rem;
                    text-align: center;
                    font-size: 3rem;
                }
            }
            .top-card {
                .Alquiler {
                    left: 12%;
                }
            }
        }
        .content-body {
            &_bottom {
                .price {
                    font-size: 1.5rem;
                }
            }
        }
        .title {
            font-size: 1.8rem !important;
        }
        .location {
            i {
                font-size: 1rem !important;
            }
        }
        .details {
            font-size: 3rem !important;
        }
    }
}

@media (max-width: 992px) {
    .property-card {
        height: 100%;
        &:hover {
            .content-header {
                .type-property {
                    width: 84% !important;
                    &::after {
                        left: 96% !important;
                    }
                }
            }
        }
        .content-header {
            p {
                // left: 38%;
                font-size: 1.4rem;
            }
            .top-card {
                width: 110px;
                &::before {
                    content: "";
                    background: linear-gradient(300deg, $bg-terciary 40%, transparent 7%);
                    height: 30px;
                    width: 50px;
                    top: 0;
                    z-index: 3;
                    left: -39px;
                    position: absolute;
                }
                span {
                    font-size: 1rem;
                    top: -2px;
                    left: 0px;
                }
                &.Alquiler {
                    // left: 0px;
                    span {
                        // left: 1rem;
                    }
                    &::before {
                        background: linear-gradient(300deg, #fff 40%, transparent 7%);
                    }
                }
            }
            .operation-property {
                font-size: 0.7rem;
                padding: 0.4rem 1.2rem 0.4rem 1rem;
            }
            .type-property {
                font-size: 0.7rem;
            }
        }
        .content-body {
            padding: 0.8rem 0.8rem 0 0.8rem;
            .title {
                font-size: 1.3rem;
                margin-bottom: 0.8rem;
            }
            .location {
                font-size: 0.9rem;
                margin-bottom: 0.8rem;
                i {
                    font-size: 0.8rem;
                }
            }
            .details {
                &_item {
                    font-size: 0.8rem;
                }
                i {
                    font-size: 0.7rem;
                    &.icon-surface-terreno {
                        font-size: 0.9rem;
                        margin-left: 0.8rem;
                    }
                }
            }
            &_bottom {
                margin-top: 1.3rem;
                padding-bottom: 0.8rem;
                .price {
                    font-size: 1.7rem;
                }
            }
        }
        .top-operations {
            .top-card {
                height: 1.9rem;
                width: 8rem;
                padding: 0;
            }
        }
    }
}

@media (max-width: 485px) {
    .property-card {
        height: 100%;
        &:hover {
            .content-header {
                .type-property {
                    width: 65% !important;
                    &::after {
                        left: 96% !important;
                    }
                }
            }
        }
        .content-header {
            p {
                // left: 25%;
            }
        }
    }
}