.Inicio {
    .lotes {
        padding-top: 20vh;
        padding-bottom: 10vh;
        background-color: #efefef;
        h2 {
            font-size: $secondary-color;
            font-weight: 400;
            font-size: 4rem;
            line-height: 1;
            span {
                color: $primary-color;
            }
        }
        p {
            font-size: 1.5rem;
            color: $secondary-color;
            margin-bottom: 60px;
        }
        .btn {
            margin-top: 8vh;
        }
        .grid-system {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(47%, 2fr));
            grid-template-rows: minmax(10vh, auto);
            grid-auto-flow: dense;
            height: 100vh;
            grid-gap: 20px;
        }
    }
}

@media (max-width:992px) {
    .Inicio {
        .lotes {
            padding-top: 10vh;
            h2 {
                font-size: 3rem;
                line-height: 1;
            }
            p {
                font-size: 1.2rem;
            }
            .grid-system {
                display: block;
                grid-template-columns: unset;
                grid-template-rows: unset;
                grid-auto-flow: unset;
                height: unset;
                grid-gap: unset;
            }
        }
    }
}