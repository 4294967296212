@font-face {
  font-family: "FR Hopper";
  src: url("../../fonts/FR-Hopper.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "FR Hopper 430";
  src: url("../../fonts/FRHopper430.woff2") format("woff2"),
    url("../../fonts/FRHopper430.woff") format("woff"),
    url("../../fonts/FRHopper430.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato-Thin";
  src: url("../../fonts/lato/Lato-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato-ThinItalic";
  src: url("../../fonts/lato/Lato-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato-Light";
  src: url("../../fonts/lato/Lato-Light.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato-LightItalic";
  src: url("../../fonts/lato/Lato-LightItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato-Regular";
  src: url("../../fonts/lato/Lato-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato-Italic";
  src: url("../../fonts/lato/Lato-Italic.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato-Medium";
  src: url("../../fonts/lato/Lato-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato-MediumItalic";
  src: url("../../fonts/lato/Lato-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato-Semibold";
  src: url("../../fonts/lato/Lato-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato-SemiboldItalic";
  src: url("../../fonts/lato/Lato-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato-Bold";
  src: url("../../fonts/lato/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato-BoldItalic";
  src: url("../../fonts/lato/Lato-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato-Black";
  src: url("../../fonts/lato/Lato-Black.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato-BlackItalic";
  src: url("../../fonts/lato/Lato-BlackItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato-Heavy";
  src: url("../../fonts/lato/Lato-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato-HeavyItalic";
  src: url("../../fonts/lato/Lato-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-Thin";
  src: url("../../fonts/raleway/Raleway-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway-ThinItalic";
  src: url("../../fonts/raleway/Raleway-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-ExtraLight";
  src: url("../../fonts/raleway/Raleway-ExtraLight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway-ExtraLightItalic";
  src: url("../../fonts/raleway/Raleway-ExtraLightItalic.ttf")
    format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-Light";
  src: url("../../fonts/raleway/Raleway-Light.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway-LightItalic";
  src: url("../../fonts/raleway/Raleway-LightItalic.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-Regular";
  src: url("../../fonts/raleway/Raleway-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway-Italic";
  src: url("../../fonts/raleway/Raleway-Italic.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-Medium";
  src: url("../../fonts/raleway/Raleway-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway-MediumItalic";
  src: url("../../fonts/raleway/Raleway-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-SemiBold";
  src: url("../../fonts/raleway/Raleway-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway-SemiBoldItalic";
  src: url("../../fonts/raleway/Raleway-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-Bold";
  src: url("../../fonts/raleway/Raleway-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway-BoldItalic";
  src: url("../../fonts/raleway/Raleway-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-Black";
  src: url("../../fonts/raleway/Raleway-Black.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway-BlackItalic";
  src: url("../../fonts/raleway/Raleway-BlackItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-ExtraBold";
  src: url("../../fonts/raleway/Raleway-ExtraBold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Raleway-ExtraBoldItalic";
  src: url("../../fonts/raleway/Raleway-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon.eot?kg932n");
  src: url("../../fonts/icomoon.eot?kg932n#iefix") format("embedded-opentype"),
    url("../../fonts/icomoon.ttf?kg932n") format("truetype"),
    url("../../fonts/icomoon.woff?kg932n") format("woff"),
    url("../../fonts/icomoon.svg?kg932n#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-row:before {
  content: "\e905";
}
.icon-grid:before {
  content: "\e906";
}
.icon-arrow:before {
  content: "\e904";
}
.icon-share:before {
  content: "\e900";
}
.icon-sup:before {
  content: "\e901";
}
.icon-check:before {
  content: "\e902";
}
.icon-instagram:before {
  content: "\e903";
}
.icon-circle_text_black:before {
  content: "\e907";
}
.icon-circle_text_red:before {
  content: "\e908";
  color: #c42127;
}
.icon-gym:before {
  content: "\e909";
}
.icon-bathroom:before {
  content: "\e90a";
  color: #646464;
}
.icon-bed:before {
  content: "\e90b";
  color: #646464;
}
.icon-car:before {
  content: "\e90c";
  color: #646464;
}
.icon-eye:before {
  content: "\e90d";
}
.icon-facebook:before {
  content: "\e90e";
}
.icon-heart:before {
  content: "\e90f";
}
.icon-home:before {
  content: "\e910";
}
.icon-location:before {
  content: "\e911";
}
.icon-send:before {
  content: "\e945";
}
.icon-whatsapp:before {
  content: "\ea93";
}
