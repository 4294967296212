.emprendimientos {
    background-color: $bg-quarter;
    .lotes {
        padding-top: 10vh;
        padding-bottom: 5vh;
        .container-fluid {
            max-width: 80%;
            .span_emprendimientos {
                color: $primary-color;
                font-size: 1.2rem;
            }
            .grid_item {
                height: 50vh;
                transform: none !important;
                transition: none !important;
                overflow: hidden;
                &::after {
                    content: "";
                    background: $primary-color;
                    height: 10%;
                    width: 10%;
                    bottom: 0;
                    bottom: 0;
                    right: 0;
                    position: absolute;
                }
                &::before {
                    content: "";
                    z-index: 2;
                    background-image: url("../../images/look-icon.png");
                    background-repeat: no-repeat;
                    background-position: right bottom;
                    background-size: cover;
                    height: 151px;
                    width: 171px;
                    bottom: 0;
                    display: block;
                    position: absolute;
                    right: 0;
                }
                .hover {
                    display: none !important;
                    a {
                        background: $primary-color !important;
                    }
                }
                &:hover {
                    // background: $bg-primary;
                    cursor: pointer;
                    &::after {
                        content: "";
                        background: rgba($primary-color, 0.5);
                        height: 100%;
                        width: 100%;
                        transition: 0.5s ease all;
                    }
                    .hover {
                        background: transparent;
                        text-align: center;
                        height: 100%;
                        display: flex !important;
                        div {
                            h5,
                            p {
                                color: $bg-quarter;
                            }
                        }
                        a {
                            margin-top: 1rem;
                            border: none !important;
                            transition: none !important;
                            &:hover {
                                background-color: $primary-color !important;
                                opacity: .8;
                                color: $bg-quarter;
                            }
                        }
                    }
                    img {
                        // opacity: 0.5;
                    }
                    &::before {
                        display: none;
                    }
                    .featured__content {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                        justify-content: center;
                        width: 100%;
                        height: 100%;
                        // background: #ed362dbf;
                        background: transparent linear-gradient( 123deg, #ed372dd2 0%, #cc3830ce 47%, #951612c7 100%) 0% 0% no-repeat padding-box;
                        &__title {
                            color: #fff;
                        }
                        &__location {
                            color: #fff;
                            i {
                                margin-right: 1rem;
                            }
                        }
                        &__btn {
                            background: white;
                            border: none;
                            color: black;
                            padding: 1.5rem !important;
                            transform: none !important;
                            width: 60px;
                            font-size: 1.6rem;
                            &:hover {
                                background: $bg-primary;
                                color: #fff;
                            }
                        }
                    }
                }
            }
            h2 {
                margin: 2rem 0 7rem 0;
                font-size: 5rem;
                font-weight: 400;
                color: $bg-primary;
                font-family: 'Raleway-ExtraBold', sans-serif;
            }
        }
    }
}

@media (max-width: 992px) {
    .emprendimientos {
        .lotes {
            padding-top: 3rem;
            padding-bottom: 3rem;
            .container-fluid {
                max-width: 100%;
                h2 {
                    margin: 1rem 0 4rem 0;
                    font-size: 2rem;
                }
            }
        }
    }
}