header {
    background-color: $bg-primary;
    position: fixed;
    width: 100%;
    z-index: 999999;
    // box-shadow: 0px 10px 15px #00000033;
    &.collapsedHeader {
        nav {
            height: 4.5rem;
            .logo {
                width: 6rem;
            }
        }
    }
    nav {
        width: 100%;
        transition: .6s ease all;
        height: 9rem;
        .logo {
            width: 11rem;
        }
        .logo-footer {
            display: none;
        }
        ul {
            padding-top: 1.2rem;
            padding-left: 17vw;
            li {
                // margin-left: 4.5rem;
                list-style: none;
                a {
                    text-decoration: none;
                    font-size: 1rem;
                    color: #ffffff;
                    text-transform: uppercase;
                    position: relative;
                    transition: .2s ease all;
                    padding-bottom: 0.5rem;
                    &:hover {
                        color: #fff;
                        &::before {
                            width: 100%;
                            left: 0;
                            right: 0;
                            opacity: 1;
                        }
                    }
                    &::before {
                        content: '';
                        transition: .15s ease-in-out, .0s ease-in-out opacity;
                        position: absolute;
                        left: 45%;
                        bottom: 0;
                        right: 45%;
                        opacity: 0;
                        width: 10%;
                        height: 2px;
                        background-color: $primary-color;
                    }
                }
                &.active {
                    a {
                        border-bottom: 2px solid #ffffff;
                    }
                }
            }
            .social-header {
                line-height: 1.5;
                display: flex;
                .icons {
                    margin-right: 1rem;
                    height: 1.563rem;
                    align-items: center;
                    justify-content: center;
                    vertical-align: middle;
                    width: 1.563rem;
                    align-self: center;
                    // background: $bg-secondary;
                    border-radius: 100%;
                    transition: 0.3s ease all;
                    display: flex;
                    .fix-pt {
                        padding-top: 2px;
                    }
                    a {
                        text-decoration: none;
                        line-height: 0rem;
                        align-items: center;
                    }
                    .icon-facebook {
                        font-size: 1.45rem;
                        line-height: 0;
                    }
                    i {
                        transition: 0.3s ease all;
                        color: $primary-color;
                        font-size: 1.7rem;
                    }
                    &.fav-icon {
                        background: #efefef;
                        border-radius: 2px;
                        i {
                            color: $primary-color;
                            font-size: .9rem;
                            &.notActived {
                                text-decoration: none;
                            }
                        }
                        &:hover {
                            background-color: $primary-color !important;
                            i {
                                color: #fff !important;
                            }
                        }
                    }
                    &:hover {
                        color: #fff !important;
                        i {
                            color: #fff !important;
                        }
                    }
                    &:nth-child(1) {
                        // margin-left: 2vw;
                    }
                }
            }
        }
    }
    &.results-nav {
        nav ul li a {
            // color: $secondary-color;
        }
    }
    &.home-nav {
        nav ul li a {
            color: #fff;
        }
    }
    &.contact-nav {
        background-color: #fff;
        padding-bottom: 4rem;
        ul {
            li {
                a {
                    color: $secondary-color;
                }
            }
        }
    }
}

@media (max-width: 992px) {
    header {
        display: flex;
        align-items: center;
        height: 96px;
        &.contact-nav {
            padding-bottom: 0;
            background-color: rgba(#000, 0.4);
        }
        nav {
            padding-top: 0rem;
            height: unset;
            z-index: 99999;
            &.mobile-small-container {
                padding: 0px .5rem;
            }
            .menu-toggle {
                background-color: transparent;
                position: relative;
                z-index: 99999;
                border: none;
                width: unset;
                cursor: pointer;
                display: block;
                transition: 0.4s ease-in all !important;
                i {
                    display: block;
                    background-color: #fff;
                    height: 3px;
                    margin-bottom: 0.45rem;
                    transition: 0.4s ease-in-out all !important;
                    &:nth-child(1) {
                        width: 1.7rem;
                    }
                    &:nth-child(2) {
                        width: 1.7rem;
                    }
                    &:nth-child(3) {
                        width: 1.7rem;
                        margin-bottom: 0;
                    }
                }
                &.open {
                    i {
                        &:nth-child(1) {
                            transform: rotate(-45deg) translate(-0.46rem, 0rem);
                            width: 1.7rem;
                        }
                        &:nth-child(2) {
                            opacity: 0;
                            display: none;
                        }
                        &:nth-child(3) {
                            width: 1.7rem;
                            transform: rotate(225deg) translate(0.4rem, 0.04rem);
                        }
                    }
                }
            }
            .fav-icon {
                position: relative;
                z-index: 999999;
                margin-top: 2px;
                line-height: 1;
                a {
                    text-decoration: none;
                    i.notActived {
                        color: $bg-primary;
                        background-color: #fff;
                        border-radius: 2px;
                        text-decoration: none;
                    }
                    i {
                        width: 1.5rem;
                        height: 1.5rem;
                        font-size: 1rem;
                        padding: .35rem;
                    }
                }
            }
            .logo {
                width: 8rem;
                position: relative;
                z-index: 999999;
            }
            ul {
                position: fixed;
                left: 200%;
                background-color: $bg-primary;
                bottom: 0;
                padding: 0;
                top: 0;
                height: 100vh;
                transition: 0.4s ease all;
                width: 100vw;
                right: 0;
                padding-top: 9rem;
                &.open {
                    left: 0;
                    li {
                        padding-left: 1.5rem;
                    }
                    .social-header {
                        padding-left: 1.5rem;
                    }
                }
                &::before {
                    top: 0;
                    width: 100%;
                    content: "";
                    height: 5.9rem;
                    position: absolute;
                    background-color: $bg-primary;
                }
                li {
                    margin-bottom: 1vh;
                    a {
                        color: rgba(#fff, 1) !important;
                        font-size: 4.5vh;
                        letter-spacing: 0.5px;
                        font-weight: 300;
                    }
                }
                .social-header {
                    position: absolute;
                    bottom: 15vh;
                    .icons {
                        width: 2.5rem;
                        height: 2.5rem;
                    }
                }
            }
        }
    }
}